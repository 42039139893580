import React from "react";
import { Button, Icon, Tooltip, Collapse, Input, Table, Typography, Radio } from "antd";
import { observer } from "mobx-react";
import { Header } from "./Header";
import { Store } from "./Store";
import IFunction from "./IFunction";
import { observable } from "mobx";
import AccentFolder from "./AccentFolder";
import * as PriceCalculator from "./Utils/PriceCalculator"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NumberFormat from 'react-number-format';
import { faYenSign, faMemory, faMicrochip, faKeyboard, faVolumeUp, faEye, faLink, faBroadcastTower, faBatteryFull, faWeight, faCog } from '@fortawesome/free-solid-svg-icons'
import { SortOrder } from "antd/lib/table";
import { RadioChangeEvent } from "antd/lib/radio";
import { push } from "./App";

const { Search } = Input;
const { Panel } = Collapse;
const { Text } = Typography;

@observer export class FunctionsPage extends React.Component {
    private static sortedColumnIndex = 0;
    private obs = observable({
        words: [] as string[],
        status: "enabled",
    });

    private static columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (t: string, r: any) =>
                <Button type='link' onClick={() => push('/functions/' + r.id)}>{t}</Button>,
            ellipsis: true,
            textWrap: 'word-break',
            width: "20%",
            sorter: (a: IFunction, b: IFunction) => { return a.name.localeCompare(b.name) },
            sortOrder: 'ascend' as SortOrder,
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: "5%",
            sorter: (a: IFunction, b: IFunction) => { return a.id.localeCompare(b.id) },
        },
        {
            title: 'Maturity',
            dataIndex: 'maturity',
            key: 'maturity',
            width: "5%",
            sorter: (a: IFunction, b: IFunction) => { return a.maturity.localeCompare(b.maturity) },
        },
        {
            title: (
                <Tooltip title={<span>Number of times used in other projects</span>}>
                    <Text strong>Projects</Text>
                </Tooltip>),
            dataIndex: 'projects',
            align: 'center' as 'center',
            key: 'projects',
            width: "5%",
            // sorter: (a: IFunction, b: IFunction) => { return a.projects.localeCompare(b.projects) },
            render: (_: string, record: IFunction) => (
                <div>
                    {record.usedBy.length + record.usedByLegacy.length}
                </div>)
        },
        {
            title: 'Prices',
            dataIndex: '',
            key: 'totalPrice',
            width: "5%",
            sorter: (a: IFunction, b: IFunction) => { return PriceCalculator.calculateFunctionTotalPrice(a) - PriceCalculator.calculateFunctionTotalPrice(b); },
            render: (t: string, r: IFunction) => <NumberFormat value={PriceCalculator.calculateFunctionTotalPrice(r)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} renderText={value => <div><FontAwesomeIcon icon={faYenSign} />{value}</div>} />,
        },
        {
            title: 'CI',
            dataIndex: '',
            key: 'totalPriceSe',
            width: "3%",
            render: (t: string, r: IFunction) => {
                var totalPriceSe = PriceCalculator.calculateFunctionTotalPercentagePriceSe(r);
                return (
                    // <Tooltip title={<span>Percentage of Silicon Expert prices : {totalPriceSe}</span>}>`${value}`)}
                    <Tooltip title={<span>Percentage of Silicon Expert prices : {totalPriceSe.toFixed(2)}% </span>}>
                        <Icon style={totalPriceSe > 20 ? { color: '#cf1322' } : { color: '#2fcf13' }} type={totalPriceSe < 20 ? 'check-circle' : 'exclamation-circle'} />
                    </Tooltip>
                );
            }
        },
        {
            title: 'Resources',
            align: 'center' as 'center',
            dataIndex: 'urlGitlab',
            key: 'urlGitlab',
            width: "9%",
            render: (t: string, r: IFunction) =>
                <Tooltip title={<span>{r.urlGitlab}</span>}>
                    <a href={r.urlGitlab} target="_blank" rel="noopener noreferrer">{r.urlGitlab === "" ? "" : <FontAwesomeIcon icon={faLink} />}</a>
                </Tooltip>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a: IFunction, b: IFunction) => { return a.description.localeCompare(b.description) },
        },
    ];
    private search = (d: IFunction) => this.obs.words.length === 0
        || this.obs.words.some(w =>
            AccentFolder.match(d.category, w)
            || AccentFolder.match(d.description, w)
            || AccentFolder.match(d.name, w)
            || AccentFolder.match(d.note, w)
            || AccentFolder.match(d.maturity, w)
            || AccentFolder.match(d.urlGitlab, w)
        );

    private onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        FunctionsPage.columns[FunctionsPage.sortedColumnIndex].sortOrder = undefined;
        FunctionsPage.sortedColumnIndex = FunctionsPage.columns.map(c => c.key).indexOf(sorter.columnKey);
        FunctionsPage.columns[FunctionsPage.sortedColumnIndex].sortOrder = (sorter.order === undefined) ? 'ascend' : sorter.order;
        this.forceUpdate();
    }

    public render() {
        const missing = [...Store.store.functions.values()]
            .filter(v => v.category === "")
            .filter(this.search);
        const radio = [...Store.store.functions.values()]
            .filter(v => v.category === "radio")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const sensors = [...Store.store.functions.values()]
            .filter(v => v.category === "sensors")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const hmi = [...Store.store.functions.values()]
            .filter(v => v.category === "hmi")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const audio = [...Store.store.functions.values()]
            .filter(v => v.category === "audio")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const power = [...Store.store.functions.values()]
            .filter(v => v.category === "power")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const processing = [...Store.store.functions.values()]
            .filter(v => v.category === "processing")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const memory = [...Store.store.functions.values()]
            .filter(v => v.category === "memory")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const measuring = [...Store.store.functions.values()]
            .filter(v => v.category === "measuring")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");
        const others = [...Store.store.functions.values()]
            .filter(v => v.category === "others")
            .filter(this.search)
            .filter(v => this.obs.status === 'all' ? true : v.status === "enabled");


        return (
            <div>
                <Header />

                <Search
                    size="large"
                    placeholder="Start typing to search..."
                    onChange={e => { this.obs.words = e.target.value.split(" ").filter(w => w !== ""); }} style={{ width: '100%', padding: 10 }}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10 }}>
                    <Radio.Group
                        defaultValue="enabled"
                        buttonStyle="solid"
                        value={this.obs.status}
                        onChange={(e: RadioChangeEvent) => {
                            this.obs.status = e.target.value;
                        }} >
                        <Radio.Button value="enabled">Enabled Only</Radio.Button>
                        <Radio.Button value="all">All</Radio.Button>
                    </Radio.Group>
                </div>
                <div style={{ width: '100%', height: Store.store.height - 160, padding: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Collapse defaultActiveKey={["missing", "radio", "sensors", "hmi", "audio", "power", "processing", "memory", "measuring", "others"]}
                        expandIconPosition={"right"} bordered={false}>

                        {missing.length > 0
                            && (<Panel header="Missing Category" key="missing" style={{ background: 'red' }}>
                                <Table
                                    rowKey={r => r.id}
                                    dataSource={missing}
                                    columns={FunctionsPage.columns}
                                    pagination={false}
                                    onChange={this.onTableChange} />
                            </Panel>)}

                        {radio.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faBroadcastTower} />
                                <Text >-Radio</Text>
                            </React.Fragment>}
                                key="radio" >
                                <Table rowKey={r => r.id}
                                    dataSource={radio}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns}
                                    pagination={false}
                                    size="small" />
                            </Panel>)}
                        {sensors.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faEye} />
                                <Text >-Sensor</Text>
                            </React.Fragment>}
                                key="sensors" >
                                <Table rowKey={r => r.id}
                                    dataSource={sensors}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                        {hmi.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faKeyboard} />
                                <Text >-HMI</Text>
                            </React.Fragment>} key="hmi" >
                                <Table rowKey={r => r.id}
                                    dataSource={hmi}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                        {audio.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faVolumeUp} />
                                <Text >-Audio</Text>
                            </React.Fragment>}
                                key="audio" >
                                <Table rowKey={r => r.id}
                                    dataSource={audio}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                        {power.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faBatteryFull} />
                                <Text >-Power</Text>
                            </React.Fragment>}
                                key="power" >
                                <Table rowKey={r => r.id}
                                    dataSource={power}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                        {processing.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faMicrochip} />
                                <Text >-Processing</Text>
                            </React.Fragment>}
                                key="processing" >
                                <Table rowKey={r => r.id}
                                    dataSource={processing}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                        {memory.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faMemory} />
                                <Text >-Memory</Text>
                            </React.Fragment>}
                                key="memory" >
                                <Table rowKey={r => r.id}
                                    dataSource={memory}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                        {measuring.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faWeight} />
                                <Text >-Measuring</Text>
                            </React.Fragment>}
                                key="measuring" >
                                <Table rowKey={r => r.id}
                                    dataSource={measuring}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                        {others.length > 0
                            && (<Panel header={<React.Fragment>
                                <FontAwesomeIcon icon={faCog} />
                                <Text >-Others</Text>
                            </React.Fragment>}
                                key="others" >
                                <Table rowKey={r => r.id}
                                    dataSource={others}
                                    onChange={this.onTableChange}
                                    columns={FunctionsPage.columns} pagination={false} size="small" />
                            </Panel>)}
                    </Collapse>
                </div>
            </div>
        );
    }
}


// HIDDEN ELEMENTS

    //
    // {
    //     title: 'Fixed cost CN',
    //     dataIndex: 'fixcostCn',
    //     key: 'fixcostCn',
    // },
    // {
    //     title: 'Fixed cost FR',
    //     dataIndex: 'fixcostFr',
    //     key: 'fixcostFr',
    // },
    // {
    //     title: 'Note',
    //     dataIndex: 'note',
    //     key: 'note',
    // },
    // {
    //     title: 'Additional Price',
    //     dataIndex: 'additionalPrice',
    //     key: 'additionalPrice',
    // },
