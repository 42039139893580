import { observer } from "mobx-react";
import React from "react";
import { Button, Progress, Table, Result, message, Upload, Divider, Icon, Col, Row, Typography, Card, Statistic } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYenSign } from '@fortawesome/free-solid-svg-icons'
import NumberFormat from 'react-number-format';
import { saveAs } from "file-saver"
import { Store } from "./Store";
import IPrice from "./IPrice"
import IFunction from "./IFunction"


const { Text } = Typography;
const { Paragraph } = Typography;
const { Dragger } = Upload;

@observer export class AdminErp extends React.Component {
  state = {
    resultUpload: "info",
    resultMessage: ""
  }

  getNbComponentWithErpPricePercent(): number {
    var countTotal = 0;
    var countWithErpPrice = 0;
    Store.store.functions.forEach(function (item: IFunction) {
      if (item.listComponents) {
        countTotal += item.listComponents.length;
        item.listComponents.forEach(compo => {
          if (compo.erpPrice && compo.erpPrice !== 0) {
            countWithErpPrice += 1;
          }
        })
      }
    })
    if (countTotal === 0)
      return (0)
    return ((100 / countTotal) * countWithErpPrice)
  }

  getNbPriceTotal() : number {
    return (Store.store.prices.size);
  }

  getNbPriceTotalAverage() : number {
    var totalPrice = 0;

    Store.store.prices.forEach(function (price: IPrice) {
      totalPrice += price.price;
    });
    return (totalPrice / Store.store.prices.size)
  }

  getAverageLeadTime() : number {
    var totalLeadTime = 0;
    var count = 0;

    Store.store.prices.forEach(function (price: IPrice) {
      if (price.leadTime !== undefined && price.leadTime !== 0) {
        totalLeadTime += price.leadTime;
        count++;
      }
    });
    return (totalLeadTime / count / 7)
  }

  getComponentWithoutLeadTime() : number {
    var count = 0;

    Store.store.prices.forEach(function (price: IPrice) {
      if (price.leadTime !== undefined && price.leadTime === 0) {
        count++;
      }
    });
    return (count)
  }

  getNbComponentWithManualPricePercent(): number {
    var countTotal = 0;
    var countWithErpPrice = 0;
    Store.store.functions.forEach(function (item: IFunction) {
      if (item.listComponents) {
        countTotal += item.listComponents.length;
        item.listComponents.forEach(compo => {
          if (compo.uploadedPrice && compo.uploadedPrice !== 0) {
            countWithErpPrice += 1;
          }
        })
      }
    })
    if (countTotal === 0)
      return (0)
    return ((100 / countTotal) * countWithErpPrice)
  }

  processResultUpload(info: any) {
    const { status } = info.file;
    if (status === 'done') {
      this.setState({
        resultUpload: "success",
        resultMessage: info.file.response.message
      })
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      if (info.file.error) {
        this.setState({ resultUpload: "serverError" })
      } else {
        this.setState({
          resultUpload: "error",
          resultMessage: info.file.response.error
        })
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };


  public render() {

    const columns = [
      {
        title: 'CPN (ERPID)',
        dataIndex: 'erpId',
        key: 'erpId',
        width: "20%",
      },
      {
        title: 'MPN',
        dataIndex: 'mpn',
        key: 'mpn',
        width: "35%",
      },
      {
        title: 'Price',
        dataIndex: 'price',
        render: (t: string, r: IPrice) => <NumberFormat value={r.price} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} renderText={value => <div><FontAwesomeIcon icon={faYenSign} />{value}</div>} />,
      },
      {
        title: 'Last Update',
        dataIndex: 'dateLastUpdated',
        key: 'dateLastUpdated',
      },
      {
        title: 'Lead Time (weeks)',
        dataIndex: 'leadTime',
        key: 'leadTime',
        render: (t: string, r: IPrice) => { return (Math.round(r.leadTime / 7)) }
      },
    ];

    const propsUploadDragger = {
      accept: '.csv',
      name: 'prices',
      action: process.env.REACT_APP_API_URL + '/erpPrices',
      headers: {
        authorization: "Bearer " + Store.store.adminApiKey
      },
      multiple: false,
    }
    return (
      <div>
        <Row style={{marginRight : '20px', marginLeft : '20px' }} gutter={16}>
          <Col span={6}>
            <Divider>Upload Price</Divider>
            <Dragger {...propsUploadDragger} onChange={info => this.processResultUpload(info)}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Drop the CSV file here.
                </p>
              <p className="ant-upload-hint">Click or drag file to this area to upload</p>
            </Dragger>
            {this.state.resultUpload === "success" && <Result
              status="success"
              title={this.state.resultMessage}
              subTitle="Prices will be updated soon, it might take a few minutes"
            />}
            {this.state.resultUpload === "error" && <Result
              status="error"
              title="Submission Failed"
              subTitle="Please check and modify the following information before resubmitting."
            >
              <div className="desc">
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    The content you submitted has the following error:
                   </Text>
                </Paragraph>
                <Paragraph>
                  <Icon style={{ color: 'red' }} type="close-circle" /> {this.state.resultMessage}
                </Paragraph>
              </div>
            </Result>}
            {this.state.resultUpload === "serverError" && <Result
              status="500"
              title="500"
              subTitle="Sorry, the server has an issue. Try again or contact your administrator."
            />}
            <Button style={{ width: "100%", marginTop: "16px" }} type="primary" icon="download" size={"large"} onClick={(e) => {
              saveAs(process.env.REACT_APP_API_URL + '/static/file/priceUpdateTemplate.csv', "priceUpdateTemplate.csv");
            }}> Download Template Price Update </Button>
          </Col>
          <Col span={10}>
            <Divider>Current Price List</Divider>
            <Table
              rowKey={r => r.erpId}
              dataSource={[...Store.store.prices.values()]}
              columns={columns}
              pagination={{ pageSize: 22 }}
              scroll={{ y: '100%' }}
              size={"small"}
            />
          </Col>
          <Col span={8}>
            <Divider>Component's prices</Divider>
            <div style={{ textAlign: 'center', fontSize: "0.2em" }}>
            <Row gutter={1}>
              <Col span={12}>
              <Progress type="dashboard" style={{ paddingTop: '20px', textSizeAdjust: "true" }} width={220} percent={Math.round(this.getNbComponentWithErpPricePercent())} format={percent => { return (<div style={{ fontSize: "0.9em" }}>{percent}% - ERP</div>) }} />
              </Col>
              <Col span={12}>
              <Progress type="dashboard" style={{ paddingTop: '20px', textSizeAdjust: "true" }} width={220} percent={Math.round(this.getNbComponentWithManualPricePercent())} format={percent => { return (<div style={{ fontSize: "0.7em" }}>{percent}% - Manual</div>) }} />
              </Col>
              </Row>
            </div>
            <div style={{fontSize: "0.2em" }}>
            <Row gutter={6}>
              <Col span={12}>
                <Card>
                 <Statistic
                   title="ERP prices"
                   value={this.getNbPriceTotal()}
                   valueStyle={{ color: '#cf1322' }}
                 />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                 <Statistic
                   title="ERP Price Average"
                   precision={2}
                   value={this.getNbPriceTotalAverage()}
                   valueStyle={{ color: '#cf1322' }}
                   suffix="¥"
                 />
                </Card>
              </Col>
            </Row>
          </div>
          <div style={{fontSize: "0.2em" }}>
          <Row gutter={6}>
            <Col span={12}>
              <Card>
               <Statistic
                 title="Lead Time missing"
                 value={this.getComponentWithoutLeadTime()}
                 valueStyle={{ color: '#cf1322' }}
               />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
               <Statistic
                 title="Average Lead Time"
                 precision={2}
                 value={this.getAverageLeadTime()}
                 valueStyle={{ color: '#cf1322' }}
                 suffix="Week"
               />
              </Card>
            </Col>
          </Row>
        </div>
        </Col>
      </Row>
      </div>
    )
  }
}
