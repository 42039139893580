import { observer } from "mobx-react";

import React from "react";
import Iframe from 'react-iframe';
import {Col, Row} from "antd";

@observer export class Documentation extends React.Component {

  public render() {
    return (
      <div>
        <Row gutter={16}>
          <Col span={23}>
            <div style={{ height: "88vh" }}>
              <Iframe width="100%" height="100%" url={'https://ressources.ask.altyor.solutions/mpnAndCpnTutorial.html'} />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
