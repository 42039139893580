import React from "react";
import { message, Row, Col, Typography, Tooltip, Collapse, Table, Icon, Affix, Button } from "antd";
import { QuotationForm } from "./QuotationForm";
import { observable, autorun, IReactionDisposer } from "mobx";
import { AddFunctionModal } from "./AddFunctionModal";
import { Header } from "./Header";
import { push } from "./App";
import { Store } from "./Store";
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import IQuotation from "./IQuotation";
import IFunction from "./IFunction";
import "firebase/storage";

import XLSX from "@sheet/core";

const { Panel } = Collapse;
const { Text } = Typography;

interface IObsQuotation {
    quotation?: IQuotation;
    category: string,
    visible: boolean,
}

const genExtra = (page: QuotationPage, category: string) => (
    <Icon
        type="plus-circle"
        onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            page.obs.visible = true;
            page.obs.category = category;
            event.stopPropagation();
        }}
    />
);

@inject('routing')
@observer export class QuotationPage extends React.Component<{ routing: RouterStore }, {}> {

    obs: IObsQuotation = observable({
        visible: false,
        category: "",
    });

    private dispose?: IReactionDisposer;

    private columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "15%",
            render: (t: string, r: any) =>
                <Button type='link' onClick={() => push(`/quotations/${this.obs.quotation!.id}/versions/${this.obs.quotation!.version}/functions/${r.id}`)}>{t}</Button>,

        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: "5%"
        },
        {
            title: 'Maturity',
            dataIndex: 'maturity',
            key: 'maturity',
            width: "7%"
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',

        },
        {
            title: (
                <Tooltip title={<span>Number of times used in projects (Validated quotation)</span>}>
                    <Text strong>Projects</Text>
                </Tooltip>),
            dataIndex: 'projects',
            render: function (_: string, record: IFunction) {
                if (record.usedBy !== undefined && record.usedByLegacy !== undefined) {
                    return (<div>{record.usedBy.length + record.usedByLegacy.length}</div>)
                } else {
                    return (<div>0</div>)
                }
            },
            key: 'projects',
            width: "10%",
        },
        {
            title: 'Action',
            render: (_: string, record: IFunction) => (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Icon type="delete" onClick={e => this.deleteFunction(record.id)} />
                    {this.canRefresh(record) && <Icon type="reload" onClick={e => this.refreshFunction(record.id)} />}
                </div>
            ),
            width: "9%",
        },
    ];

    private canRefresh(f: IFunction): boolean {
        return Store.store.functions.get(f.id)!.lastChange!.toMillis() !== f.lastChange!.toMillis();
    }

    private deleteFunction(id: string): void {
        this.obs.quotation!.functions = this.obs.quotation!.functions.filter(f => f.id !== id);
    }

    private refreshFunction(id: string) {
        const fresh = Store.store.functions.get(id)!;
        this.obs.quotation!.functions = this.obs.quotation!.functions.map(f => f.id === id ? fresh : f);
    }

    active: string[] = observable([]);
    public render() {
        if (this.obs.quotation === undefined) {
            return <Header />;
        }
        const active = ["radio", "sensors", "hmi", "audio", "power", "processing", "others", "measuring", "others"]
            .filter(c => [...this.obs.quotation!.functions].some(f => f.category === c));

        return (
            <div>
                <Header />
                <Row type='flex' justify='space-around'>
                    <Col span={7}>
                        <Affix offsetTop={0}>
                            <QuotationForm item={this.obs.quotation!} />
                        </Affix>
                    </Col>
                    <Col span={16} style={{ margin: 10 }} >
                        <Collapse activeKey={active} bordered={false}>
                            <Panel header="Radio" key="radio" extra={genExtra(this, "radio")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "radio")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="Sensors" key="sensors" extra={genExtra(this, "sensors")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "sensors")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="HMI" key="hmi" extra={genExtra(this, "hmi")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "hmi")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="Audio" key="audio" extra={genExtra(this, "audio")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "audio")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="Power" key="power" extra={genExtra(this, "power")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "power")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="Processing" key="processing" extra={genExtra(this, "processing")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "processing")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="Memory" key="memory" extra={genExtra(this, "memory")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "memory")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="Measuring" key="measuring" extra={genExtra(this, "measuring")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "measuring")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                            <Panel header="Others" key="others" extra={genExtra(this, "others")}>
                                <Table rowKey={r => r.name}
                                    dataSource={[...this.obs.quotation!.functions]
                                        .filter(f => f.category === "others")}
                                    expandedRowRender={record => <p style={{ margin: 0 }}><Text strong >Note : </Text>{record.note}</p>}
                                    columns={this.columns} pagination={false} size="small" />
                            </Panel>
                        </Collapse>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40, paddingRight: 10 }}>
                            <Button type='primary' onClick={async () => await this.onExport()}>Export</Button>
                            <Button type='primary' onClick={async () => await this.onSave()}>Save</Button>
                        </div>
                    </Col>
                </Row>
                <AddFunctionModal item={this.obs.quotation!} category={this.obs.category} visible={this.obs.visible} hide={() => { this.obs.visible = false; }} />
            </div>
        );
    }

    private async onSave(): Promise<void> {
        if (this.obs.quotation && this.obs.quotation.name && this.obs.quotation.name !== "") {
            await Store.saveQuotationAsync(this.obs.quotation!);
            push("/quotations/");
        } else {
            message.error('Name cannot be empty !');
        }

    }

    private async onExport(): Promise<void> {
        await Store.saveQuotationAsync(this.obs.quotation!);
        // Force reload before export
        this.obs.quotation = await Store.getQuotationAsync(this.obs.quotation!.id);

        // const url = await firebase.storage().refFromURL("gs://hp-altyor-dev.appspot.com/template.xlsx").getDownloadURL()
        const url = process.env.REACT_APP_API_URL + '/static/file/template.xlsx';
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = async _ => {
            var blob = xhr.response;
            var wb = XLSX.read(await blob.arrayBuffer(), { type: "array", cellStyles: true, sheetStubs: true });
            wb.SheetNames.forEach((n: string) => {
                const sheet = wb.Sheets[n]!;
                var range = XLSX.utils.decode_range(sheet['!ref']!);
                for (var r = range.s.r; r <= range.e.r; r++) {
                    if (QuotationPage.doesRowContainComponent(sheet, r)) {
                        const vars = this.obs.quotation!.functions
                            .flatMap(f => f.listComponents
                                .map(c => { return { quotation: this.obs.quotation!, function: f, component: c } }));
                        sheet['!ref'] = XLSX.utils.encode_range({
                            s: range.s,
                            e: { r: r + vars.length - 1, c: range.e.c }
                        });
                        for (let i = vars.length - 1; i >= 0; i--) {

                            QuotationPage.fillRow(vars[i], sheet, r, r + i);
                        }
                        break;
                    } else {
                        QuotationPage.fillRow(
                            {
                                quotation: this.obs.quotation!,
                                function: this.obs.quotation!.functions[0],
                                component: this.obs.quotation!.functions[0].listComponents[0]
                            },
                            sheet,
                            r,
                            r);
                    }
                }
            });

            XLSX.writeFile(wb, `${this.obs.quotation!.name} v${this.obs.quotation!.version}.xlsx`, { cellStyles: true, sheetStubs: true });
        };
        xhr.open('GET', url);
        xhr.send();
    }

    private static doesRowContainComponent(sheet: XLSX.WorkSheet, r: number): boolean {
        var range = XLSX.utils.decode_range(sheet['!ref']!);
        for (var c = range.s.c; c <= range.e.c; c++) {
            var cellref = XLSX.utils.encode_cell({ c: c, r: r });
            if (sheet[cellref] === undefined) {
                continue;
            }
            var cell = sheet[cellref];
            const s = cell.v as string;
            if (s !== undefined && typeof (s) === "string") {
                if (s.includes("${component.")) {
                    return true;
                }
            }
        }

        return false;
    }

    private static inject(str: string, obj: any): string {
        return str.replace(/\${(.*?)}/g, (_: any, g: string) => {
            const split = g.split(".");
            try {
                return obj[split[0]][split[1]];
            } catch {
                return g;
            }
        });
    }

    private static fillRow(vars: any, sheet: XLSX.WorkSheet, srcRow: number, dstRow: number) {
        var range = XLSX.utils.decode_range(sheet['!ref']!);
        for (var c = range.s.c; c <= range.e.c; c++) {
            var cellref = XLSX.utils.encode_cell({ c: c, r: srcRow });
            if (sheet[cellref] === undefined) {
                continue;
            }
            var cell = sheet[cellref];
            const s = cell.v as string;
            const dst = XLSX.utils.encode_cell({ c: c, r: dstRow });

            if (s !== undefined && typeof (s) === "string" && cell.f === undefined) {
                const value = QuotationPage.inject(s, vars);
                XLSX.utils.sheet_add_aoa(sheet, [[value]], { origin: dst });
                // sheet[dst].s = cell.s;

                if (value.startsWith("http")) {
                    const split = value.split("/");
                    sheet[dst].v = split[split.length - 1];
                    sheet[dst].l = { Target: value, Tooltip: value };
                    sheet[dst].s = { underline: 1, color: { rgb: "0000FF" } };
                }
            } else {
                // sheet[dst] = cell;
            }
        }
    }

    public componentDidMount() {
        this.dispose = autorun(async () => {
            let path = this.props.routing.location!.pathname;
            let split = path.split('/');
            let id = split[split.length - 1];

            const latest = Store.store.quotations.get(id);
            if (latest === undefined) {
                this.obs.quotation = {
                    id: id,
                    name: "",
                    note: "",
                    status: "in-progress",
                    projectId: "",
                    customer: "",
                    version: 1,
                    quantity: 0,
                    SMTRatio: 1,
                    pcb: { width: 0, height: 0, layer: 0, pricePerSqMeter: 0, pricePerPcb: 0 },
                    functions: [],
                };
            } else {
                this.obs.quotation = await Store.getQuotationAsync(latest.id);
            }

        });
    }

    public componentWillUnmount() {
        this.dispose!();
    }
}

//gcloud config set project hp-altyor-dev
//gsutil cors set cors.json gs://hp-altyor-dev.appspot.com
