import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Menu, Dropdown, Radio, Avatar, Button} from 'antd';
import { RouterStore } from 'mobx-react-router';
import { Store } from './Store';

var logo = require('./images/logo.png');

@inject('routing')
@observer
export class Header extends React.Component<{ routing?: RouterStore }> {

    public render() {
        const { location, push, goBack } = this.props.routing!;

        let v = "q";
        const s = location.pathname.split("/");

        if (s.length > 1 && s[1] === "quotations") {
            v = "q";
        }

        if (s.length > 1 && s[1] === "functions") {
            v = "f";
        }
        const menu = (
            <Menu style={{ width: 100 }}>
                <Menu.Item onClick={() => push('/admin/')} key="admin">
                    <Icon type="dashboard" />
                    <span>Admin</span>
                </Menu.Item>
            </Menu>
        );
        return (

            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {location.pathname !== '/' &&
                            <Button style={{ margin: 10 }} onClick={() => goBack()}> <Icon type="left" /> </Button>}
                        <Radio.Group buttonStyle="solid" style={{ margin: 10 }} value={v}>
                            <Radio.Button value="q" onClick={() => push('/quotations/')}>Quotations</Radio.Button>
                            <Radio.Button value="f" onClick={() => push('/functions/')}>Functions</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img alt={"logo"} style={{ marginTop: 10 }} src={logo} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {Store.store.isAdmin ?
                            <Dropdown overlay={menu}>
                                <Avatar size="large" icon="user" style={{ margin: 10 }} />
                            </Dropdown>
                            : <Avatar size="large" icon="user" style={{ margin: 10 }} />}
                        <h2 style={{ margin: 10 }}>{Store.store.currentUser}</h2>
                        <Button
                            onClick={() => { window.location.href = process.env.REACT_APP_URL_GATE_FE!; }}
                            shape="circle"
                            icon='logout'
                            style={{ margin: 10 }} />
                    </div>
                </div>

            </div>

        );
    }

    public async componentDidMount() {
        if (Store.store.currentUser === "") {
            await Store.login();
        }
    }
}
