import { observer } from "mobx-react";
import React from "react";
import { observable, IReactionDisposer, autorun } from "mobx";
import { Button, Card, Divider, Statistic, Row, Col, Form, InputNumber, Modal, Slider, Typography } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign, faTag, faTags, faSquare } from '@fortawesome/free-solid-svg-icons'
import IQuotation from "./IQuotation";

const { Paragraph, Text } = Typography;

@observer export class PcbaModalCalcualtor extends React.Component<{ visible: boolean, quotation: IQuotation, show: any }> {
  private dispose?: IReactionDisposer;
  private obs = observable({ totalSurface: 0 });

  enigThicknessPerSqMeter = 22;

  pricePerSqMeterTable = [
    [71, 69, 66, 63, 60, 57],
    [87, 84, 81, 78, 75, 72],
    [142, 139, 135, 127, 123, 120],
    [224, 217, 209, 202, 197, 194],
    [299, 292, 284, 277, 269, 262]
  ]

  getPricePerSquareMeter() {
    var index = 0;

    if (this.obs.totalSurface < 5) index = 0
    if (this.obs.totalSurface >= 5 && this.obs.totalSurface < 10) index = 1
    if (this.obs.totalSurface >= 10 && this.obs.totalSurface < 20) index = 2
    if (this.obs.totalSurface >= 20 && this.obs.totalSurface < 50) index = 3
    if (this.obs.totalSurface >= 50 && this.obs.totalSurface < 100) index = 4
    if (this.obs.totalSurface >= 100) index = 5

    return (this.pricePerSqMeterTable[this.props.quotation.pcb.layer][index] + this.enigThicknessPerSqMeter)
  }

  public render() {

    const marks = {
      0: 'Single Side',
      1: 'Double Side',
      2: 'ML4',
      3: 'ML6',
      4: 'ML8',
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 21 },
      },
    };
    const formItemLayoutHorizontalOne = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const formItemLayoutHorizontalTwo = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };
    return (
      <Modal width="65%" visible={this.props.visible}
        onCancel={() => this.props.show(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => this.props.show(false)}>
            Done
                  </Button>,
        ]}>
        <Form {...formItemLayout}>
          <Row gutter={16}>
            <Divider>Dimensions</Divider>
            <Col span={6}>
              <Form.Item {...formItemLayoutHorizontalOne} label="Quantity">
                <InputNumber min={0} value={this.props.quotation.quantity} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item {...formItemLayoutHorizontalTwo} label="Width (mm)">
                <InputNumber min={0} value={this.props.quotation.pcb.width} onChange={value => this.props.quotation.pcb.width = value!} precision={0.1} step={1} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item {...formItemLayoutHorizontalTwo} label="Length (mm)">
                <InputNumber min={0} value={this.props.quotation.pcb.height} onChange={value => this.props.quotation.pcb.height = value!} precision={0.1} step={1} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item {...formItemLayoutHorizontalTwo} label="Surface (m2)">
                <Statistic precision={3} value={this.obs.totalSurface} />
              </Form.Item>
            </Col>
            <Divider>PCB Type</Divider>
            <Col span={24}>
              <Form.Item {...formItemLayout} label="Layers">
                <Slider onChange={(value: any) => this.props.quotation.pcb.layer = value} marks={marks} value={this.props.quotation.pcb.layer} tooltipVisible={false} min={0} max={4} />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Price</Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Card>
                <Statistic precision={1} title="Price per square meter" value={this.props.quotation.pcb.pricePerSqMeter} suffix={<FontAwesomeIcon icon={faEuroSign} />} prefix={<FontAwesomeIcon icon={faSquare} />} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic precision={2} title="Total Price" value={this.props.quotation.pcb.pricePerSqMeter * this.obs.totalSurface} suffix={<FontAwesomeIcon icon={faEuroSign} />} prefix={<FontAwesomeIcon icon={faTags} />} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic precision={3} title="Price Per Product" value={this.props.quotation.pcb.pricePerPcb} suffix={<FontAwesomeIcon icon={faEuroSign} />} prefix={<FontAwesomeIcon icon={faTag} />} />
              </Card>
            </Col>
          </Row>
        </Form>
        <Divider> Informations </Divider>
        <Paragraph ellipsis={{ rows: 2, expandable: true }}>
          <Paragraph>
            <Text strong>IPC-A-600 J </Text> compliant, FQC according to AQL 0,65 Level II,  <Text strong> No X-Out </Text>.
            </Paragraph>
          <Paragraph>
            Drilling, V-Cut and Out-line by CNC.
            </Paragraph>
          <Paragraph>
            <Text strong> FR4 TG130 1,6mm </Text>, ENIG Finish, Finished copper thickness 1 Oz, Green solder mask, Silk-screen, E-Test 100%,   <Text strong>IPC Class2, UL & RoHs Certifications</Text>.
            </Paragraph>
        </Paragraph>
      </Modal>
    )
  }
  public componentDidMount() {

    this.dispose = autorun(() => {
      if (this.props.quotation && this.props.quotation !== undefined) {
        this.obs.totalSurface = this.props.quotation.pcb.height * this.props.quotation.pcb.width * this.props.quotation.quantity / 1000000
        this.props.quotation.pcb.pricePerSqMeter = this.getPricePerSquareMeter();
        this.props.quotation.pcb.pricePerPcb = this.props.quotation.pcb.pricePerSqMeter * this.obs.totalSurface / this.props.quotation.quantity;
      }
    });
  }
  public componentWillUnmount() {
    this.dispose!();
  }
}
