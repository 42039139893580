import IFunction from "../IFunction";
import IQuotation from "../IQuotation";
import { Store } from "../Store";

export function calculateFunctionTotalPrice(item: IFunction): number {
  var totalPrice = item.listComponents.reduce(function(price, component) {
      if (component.quantity === 0) { return price}
      if (component.uploadedPrice && component.uploadedPrice !== 0 && component.uploadedPriceCurrency) {
          if (component.uploadedPriceCurrency === "EUR") {
            return (price + component.uploadedPrice * Store.getSettingsRate().eurToRmb * component.quantity)
          } if (component.uploadedPriceCurrency === "USD") {
            return (price + component.uploadedPrice * Store.getSettingsRate().usdToRmb * component.quantity)
          } else {
            return (price + component.uploadedPrice * component.quantity)
          }
      } else if (component.erpPrice && component.erpPrice !== 0) {
        return (price + component.erpPrice * component.quantity) // RMB
      } else if (component.averagePriceSe && component.averagePriceSe !== 0) {
        return (price + component.averagePriceSe * Store.getSettingsRate().usdToRmb * component.quantity)
      } else {
        return (price + 0)
      }
    }, 0);
  return (totalPrice);
  }

export function calculateFunctionTotalPercentagePriceSe(item: IFunction): number {
    var totalPrice = calculateFunctionTotalPrice(item);

    var totalPriceSe = item.listComponents.reduce(function(price, component) {
      if (component.uploadedPrice && component.uploadedPrice !== 0) {
        return (price)
      }
      else if (component.erpPrice && component.erpPrice !== 0) {
        return (price)
      }
      else if (component.averagePriceSe && component.averagePriceSe !== 0) {
        return (price + component.averagePriceSe * Store.getSettingsRate().usdToRmb * component.quantity)
      } else {
        return (price)
      }
    }, 0)
    if (totalPrice !== 0) {
      return (100 * totalPriceSe) / totalPrice;
    }
      return 0
  }

export function calculateQuotationTotalPrice(quotation: IQuotation): number {
    var totalPrice = quotation.functions.reduce(function(price, item) {
      return price + calculateFunctionTotalPrice(item)
        }, 0);
      return totalPrice;
  }

export function calculateQuotationTotalPercentagePriceSe(quotation: IQuotation): number {
    var totalPrice = 0;
    var totalPriceSe = 0;
    quotation.functions.forEach(function(item) {
      totalPrice += calculateFunctionTotalPrice(item);
      totalPriceSe += calculateFunctionTotalSePrice(item);
    })
      if (totalPrice !== 0) {
      return (100 *totalPriceSe) / totalPrice;
    } else {
      return 0
    }
  }

function calculateFunctionTotalSePrice(item: IFunction): number {
  var totalPriceSe = item.listComponents.reduce(function(price, component) {
    if (component.uploadedPrice && component.uploadedPrice !== 0) {
      return (price)
    }
    else if (component.erpPrice && component.erpPrice !== 0) {
      return (price)
    }
    else if (component.averagePriceSe && component.averagePriceSe !== 0) {
      return (price + component.averagePriceSe * Store.getSettingsRate().usdToRmb * component.quantity)
    } else {
      return (price)
    }
  }, 0)
    return (totalPriceSe);
  }
