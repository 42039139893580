import React from "react";
import { Row, Col, Select, InputNumber, message, Icon, Avatar, Tooltip, Table, Button } from "antd";
import { FunctionForm } from "./FunctionForm";
import { observer, inject } from "mobx-react";
import { Header } from "./Header";
import { push } from "./App";
import { observable, IReactionDisposer, autorun, runInAction } from "mobx";
import { Store } from "./Store";
import { RouterStore } from "mobx-react-router";
import IFunction from "./IFunction";
import { IComponent } from "./IComponent";
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign, faYenSign, faDollarSign, faLink } from '@fortawesome/free-solid-svg-icons'

const { Option } = Select;

export interface IObsFunction {
  function?: IFunction;
}

@inject('routing')
@observer export class FunctionPage extends React.Component<{ routing: RouterStore }, {}> {
  private dispose?: IReactionDisposer;
  private obs: IObsFunction = observable({});
  private isReadonly = () => this.props.routing.location!.pathname.split("/").length === 7;

  public render() {
    if (this.obs.function === undefined) {
      return (<Header />);
    }

    const columns = [
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        width: "2%",
        sorter: (a: IComponent, b: IComponent) => { return a.quantity - b.quantity; },
        render: (t: string, r: IComponent, i: number) => {
          if (this.obs.function !== undefined) {
            return (<InputNumber min={0} value={r.quantity} disabled={this.isReadonly()} onChange={e => {
              if (e !== undefined) {
                r.quantity = e
                this.forceUpdate();
              }
            }} />)
          }
        }
      },
      {
        title: '',
        dataIndex: '',
        key: 'partImage',
        width: "2%",
        render: (r: IComponent) => <Avatar shape="square" size="default" src={r.partImage} />
      },
      {
        title: 'MPN',
        dataIndex: 'siliconExpertMpn',
        key: 'siliconExpertMpn',
        width: "10%",
        sorter: (a: IComponent, b: IComponent) => { return a.siliconExpertMpn.localeCompare(b.siliconExpertMpn) },
        render: (t: string, r: IComponent) => {
          if (r.siliconExpertMpn && r.siliconExpertMpn !== "") {
            return (<a color="bug" target="_blank" rel="noopener noreferrer" href={`${this.props.routing.location!.pathname}/components/${r.uploadedMpn}`}>{r.siliconExpertMpn}</a>)
          } else {
            return (
              <Tooltip title="Component not found on Silicon Expert, show manual input MPN">
                <a href={`${this.props.routing.location!.pathname}/components/${r.uploadedMpn}`}>{r.uploadedMpn}</a>
                <Icon style={{ color: '#cf1322' }} type={'exclamation-circle'} />
              </Tooltip>
            )
          }
        }
      },
      {
        title: 'CPN',
        dataIndex: 'uploadedCpn',
        key: 'uploadedCpn',
        width: "10%",
        sorter: (a: IComponent, b: IComponent) => { return a.uploadedCpn.localeCompare(b.uploadedCpn) },
      },
      {
        title: 'Price',
        children:
          [
            {
              title: 'Manual',
              dataIndex: 'uploadedPrice',
              key: 'uploadedPrice',
              width: "12%",
              sorter: (a: IComponent, b: IComponent) => { return a.uploadedPrice - b.uploadedPrice; },
              render: (_t: string, r: IComponent) => {
                if (this.obs.function !== undefined) {
                  return (<Row>
                    <Col span={12}>
                      <InputNumber disabled={this.isReadonly()} size="small" style={{ width: 70 }} min={0} step={0.01} value={r.uploadedPrice} onChange={e => {
                        if (e !== undefined) {
                          r.uploadedPrice = e
                          this.forceUpdate();
                        }
                      }} />

                    </Col>
                    <Col span={12}>
                      <Select disabled={this.isReadonly()} defaultValue={r.uploadedPriceCurrency} size="small" onChange={(value: any) => {
                        r.uploadedPriceCurrency = value
                        this.forceUpdate()
                          ;
                      }}>
                        <Option value="RMB"><FontAwesomeIcon icon={faYenSign} /></Option>
                        <Option value="USD"><FontAwesomeIcon icon={faDollarSign} /></Option>
                        <Option value="EUR"><FontAwesomeIcon icon={faEuroSign} /></Option>
                      </Select>
                    </Col>
                  </Row>)
                }
              }
            },
            {
              title: 'AvgSE',
              dataIndex: 'averagePriceSe',
              key: 'averagePriceSe',
              width: "7%",
              sorter: (a: IComponent, b: IComponent) => { return a.averagePriceSe - b.averagePriceSe; },
              render: (t: string, r: any) => {
                if (r.averagePriceSe && r.averagePriceSe !== 0) {
                  return (<NumberFormat value={r.averagePriceSe} displayType={'text'} decimalScale={4} fixedDecimalScale={true} thousandSeparator={true} renderText={value => <div><FontAwesomeIcon icon={faDollarSign} />{value}</div>} />)
                } else {
                  return (<span>N/A</span>)
                }
              }
            },
            {
              title: 'ERP',
              dataIndex: 'erpPrice',
              key: 'unitPrice',
              width: "7%",
              sorter: (a: IComponent, b: IComponent) => { return a.erpPrice - b.erpPrice; },
              render: (t: string, r: any) => {
                if (r.erpPrice && r.erpPrice !== 0) {
                  return (<NumberFormat value={r.erpPrice} displayType={'text'} decimalScale={4} fixedDecimalScale={true} thousandSeparator={true} renderText={value => <div><FontAwesomeIcon icon={faYenSign} />{value}</div>} />)
                } else {
                  return (<span>N/A</span>)
                }
              }
            },
          ]
      },
      {
        title: 'Supplier',
        dataIndex: 'siliconExpertSupplier',
        key: 'siliconExpertSupplier',
        width: "10%",
        sorter: (a: IComponent, b: IComponent) => { return a.siliconExpertSupplier.localeCompare(b.siliconExpertSupplier) },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a: IComponent, b: IComponent) => { return a.description.localeCompare(b.description) },

      },
      {
        title: 'Datasheet',
        dataIndex: 'datasheetUrl',
        key: 'datasheetUrl',
        width: "7%",
        render: (t: string, r: any) =>
          <Tooltip title={<span>{r.datasheetUrl}</span>}>
            <a href={r.datasheetUrl} target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faLink} /></a>
          </Tooltip>
      },
    ];

    return (
      <div>
        <Header />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '35%' }}>
            <FunctionForm item={this.obs} readonly={this.isReadonly()} />
          </div>
          <div style={{ width: '100%', height: '100%', margin: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            {/*  <Search
            //   placeholder="input search text"
            //   onSearch={(value: string) => console.log(value)}
            //   style={{ width: '100%', paddingLeft: 10 }}
            // />*/}
            <Table bordered style={{ width: '100%', paddingTop: 20 }}
              dataSource={this.obs.function.listComponents}
              rowKey={c => c.uploadedMpn}
              columns={columns} size="small" pagination={{ pageSize: 12 }} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingTop: 20, paddingRight: 20 }}>
              <Button type='primary'
                disabled={this.isReadonly()}
                onClick={async () => {
                  if (this.obs.function && this.obs.function.name && this.obs.function.name !== "") {
                    await Store.saveFunctionAsync(this.obs.function!);
                    push("/functions/");
                  } else {
                    message.error('Name cannot be empty !');
                  }
                }}>Save</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  public componentDidMount() {
    this.dispose = autorun(async () => {
      let path = this.props.routing.location!.pathname;
      let split = path.split('/');
      let id = split[split.length - 1];
      if (split.length === 3) {
        // path="/functions/:id"
        const f = Store.store.functions.get(id)!;

        runInAction(() => {
          this.obs.function = f;
        });
      }
      if (split.length === 7) {
        // path="/quotations/:idc/versions/:v/functions/:idf"
        const f = await Store.getVersionedFunctionAsync(split[2], split[4], split[6]);
        runInAction(() => { this.obs.function = f; });
        if (this.obs.function === undefined) {
          // new Quotation (not saved yet)
          const f = Store.store.functions.get(id)!;
          runInAction(() => { this.obs.function = f; });
        }
      }
    });
  }

  public componentWillUnmount() {
    this.dispose!();
  }
}
