import React from "react";
import { Tooltip, Input, Table, Modal, Typography, Button } from "antd";
import * as PriceCalculator from "./Utils/PriceCalculator"
import NumberFormat from 'react-number-format';
import { SortOrder } from "antd/lib/table";
import { Store } from "./Store";
import { observable } from "mobx";
import { observer } from "mobx-react";
import IQuotation from "./IQuotation";
import AccentFolder from "./AccentFolder";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYenSign } from '@fortawesome/free-solid-svg-icons'
import IFunction from "./IFunction";
import { push } from "./App";

const { Search } = Input;
const { Text } = Typography;

const columnsAll = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (t: string, r: any) =>
            <Button type='link' onClick={() => push('/functions/' + r.id)}>{t}</Button>,
        width: "10%",
        sorter: (a: IFunction, b: IFunction) => { return a.name.localeCompare(b.name) },
        sortOrder: 'ascend' as SortOrder,
    },
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: "5%",
        sorter: (a: IFunction, b: IFunction) => { return a.id.localeCompare(b.id) },
    },
    {
        title: 'Maturity',
        dataIndex: 'maturity',
        key: 'maturity',
        width: "10%",
        sorter: (a: IFunction, b: IFunction) => { return a.maturity.localeCompare(b.maturity) },
    },
    {
        title: (
            <Tooltip title={<span>Number of times used in other projects</span>}>
                <Text strong>Projects</Text>
            </Tooltip>),
        dataIndex: 'projects',
        key: 'projects',
        width: "10%",
        // sorter: (a: IFunction, b: IFunction) => { return a.projects.localeCompare(b.projects) },
        render: (_: string, record: IFunction) => (
            <div>
                {record.usedBy.length + record.usedByLegacy.length}
            </div>)
    },
    {
        title: 'Prices',
        dataIndex: '',
        key: 'totalPrice',
        width: "8%",
        sorter: (a: IFunction, b: IFunction) => { return PriceCalculator.calculateFunctionTotalPrice(a) - PriceCalculator.calculateFunctionTotalPrice(b); },
        render: (t: string, r: IFunction) => <NumberFormat value={PriceCalculator.calculateFunctionTotalPrice(r)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} renderText={value => <div><FontAwesomeIcon icon={faYenSign} />{value}</div>} />,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a: IFunction, b: IFunction) => { return a.description.localeCompare(b.description) },
    },
];

@observer export class AddFunctionModal extends React.Component<{
    item: IQuotation,
    category: string,
    visible: boolean,
    hide: () => void
}, {}> {
    rowSelection = observable(
        {
            selectedRowKeys: [] as string[] | number[],
            onChange: (k: string[] | number[], r: any[]) => { },
        },
    );

    private words: string[] = observable([]);

    constructor(props: { item: IQuotation, category: string, visible: boolean, hide: () => void }) {
        super(props);
        this.rowSelection.onChange = (k, _) => {
            this.rowSelection.selectedRowKeys = k;
            this.forceUpdate(); // Work around observability bug
        };
    }

    public render() {
        const except = new Set(this.props.item.functions.map(f => f.id));
        return (
            <Modal
                width="90%"
                title={`Select a ${this.props.category} Functions to add`}
                visible={this.props.visible}
                onOk={() => {
                    const keys = this.rowSelection.selectedRowKeys as string[];
                    this.props.item.functions = this.props.item.functions
                        .concat(keys.map(k => Store.store.functions.get(k)!));
                    this.props.hide();
                }
                }
                onCancel={this.props.hide}
            >
                <Search
                    size="large"
                    placeholder="Start typing to search..."
                    onChange={e => {
                        this.words = e.target.value.split(" ").filter(w => w !== "");
                    }}
                    style={{ width: '100%', padding: 10 }}
                />
                <Table rowKey={r => r.id} dataSource={[...Store.store.functions.values()]
                    .filter(d => d.category === this.props.category)
                    .filter(f => !except.has(f.id))
                    .filter(d => this.words.length === 0
                        || this.words.some(w =>
                            AccentFolder.match(d.category, w)
                            || AccentFolder.match(d.description, w)
                            || AccentFolder.match(d.name, w)
                            || AccentFolder.match(d.maturity, w)
                        )
                    )
                } rowSelection={this.rowSelection} columns={columnsAll} pagination={false} />
            </Modal>
        );
    }
}
