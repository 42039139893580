import React from "react";
import { Input, Table, Icon, Button, Typography, Tooltip, Spin } from "antd";
import { observer, inject } from "mobx-react";
import { Header } from "./Header";
import { push } from "./App";
import { Store } from "./Store";
import { observable } from "mobx";
import IQuotation from "./IQuotation";
import AccentFolder from "./AccentFolder";
import { PaginationConfig, SorterResult, TableCurrentDataSource } from "antd/lib/table";
import NumberFormat from 'react-number-format';
import { RouterStore } from "mobx-react-router";

const { Search } = Input;
const { Text } = Typography;

@inject('routing')
@observer export class QuotationsPage extends React.Component<{ routing?: RouterStore }> {
    private obs = observable({
        statusFilter: ["in-progress"],
        words: [] as string[],
    });
    private search = (d: IQuotation) => this.obs.words.length === 0
        || this.obs.words.some(w =>
            AccentFolder.match(d.customer, w)
            || AccentFolder.match(d.name, w)
            || AccentFolder.match(d.projectId, w)
        );

    private onChange = (pagination: PaginationConfig,
        filters: Record<"id" | "name" | "status" | "projectId" | "customer" | "version" | "quantity" | "SMTRatio" | "functions" | "lastChange", string[]>,
        sorter: SorterResult<IQuotation>, extra: TableCurrentDataSource<IQuotation>) => {
        this.obs.statusFilter = filters["status"];
    }

    public render() {
        if (this.props.routing!.location.search.startsWith("?token=")) {
            const antIcon = <Icon type="loading" style={{ fontSize: 300 }} spin />;
            return (
                <div style={{ height: Store.store.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin indicator={antIcon} />
                </div>
            );
        }

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (t: string, r: any) =>
                    <Button type='link' onClick={() => push('/quotations/' + r.id)}>{t}</Button>,
                width: "10%",
                sorter: (a: IQuotation, b: IQuotation) => { return a.name.localeCompare(b.name) },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                filters: [
                    {
                        text: 'In Progress',
                        value: 'in-progress',
                    },
                    {
                        text: 'Validated',
                        value: 'validated',
                    },
                    {
                        text: 'Aborted',
                        value: 'aborted',
                    },
                ],
                filteredValue: this.obs.statusFilter,
                filterMultiple: true,
                width: "10%",
                sorter: (a: IQuotation, b: IQuotation) => { return a.status.localeCompare(b.status) },
            },
            {
                title: 'Project Id',
                dataIndex: 'projectId',
                key: 'projectId',
                width: "10%",
                sorter: (a: IQuotation, b: IQuotation) => { return a.projectId.localeCompare(b.projectId) },
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                width: "8%",
                sorter: (a: IQuotation, b: IQuotation) => { return a.customer.localeCompare(b.customer) },
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                width: "6%",
                render: (t: string, r: IQuotation) => <NumberFormat value={r.quantity} displayType={'text'} thousandSeparator={true} renderText={value => <div>{value}</div>} />,
                sorter: (a: IQuotation, b: IQuotation) => a.quantity - b.quantity,
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                sorter: (a: IQuotation, b: IQuotation) => { return a.note.localeCompare(b.note) },
            },
            {
                title: 'Last change',
                dataIndex: 'lastchange',
                key: 'lastChange',
                width: "15%",
                render: (t: string, r: IQuotation) => <Text>{r.lastChange === undefined || r.lastChange === null ? "" : r.lastChange.toDate().toLocaleString()}</Text>,
                sorter: (a: IQuotation, b: IQuotation) => { return a.lastChange!.toMillis() - b.lastChange!.toMillis(); },

            },
            {
                title: 'Action',
                render: (_: string, _record: IQuotation) => (
                    <Tooltip placement="left" title="Duplicate">
                        <Icon type="copy" onClick={async _e => { await Store.duplicateQuotationAsync(_record) }} />
                    </Tooltip>
                ),
                width: "5%",
            },
        ];

        return (
            <div>
                <Header />
                <Search
                    size="large"
                    placeholder="Start typing to search..."
                    onChange={e => { this.obs.words = e.target.value.split(" ").filter(w => w !== ""); }}
                    style={{ width: '100%', paddingLeft: 10 }}
                />
                <div style={{ width: '100%', height: Store.store.height - 130, padding: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Table rowKey={r => r.id}
                        dataSource={
                            [...Store.store.quotations.values()]
                                .filter(q => this.obs.statusFilter.length === 0 || this.obs.statusFilter.some(s => q.status === s))
                                .filter(this.search)}
                        columns={columns}
                        pagination={false}
                    /* onChange={this.onChange}  */ // TODO REVERT THIS WEOIRD TS BUG
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 10 }}>
                        <Button type='primary' onClick={() => push(`/quotations/${Store.generateId()}`)}>Create</Button>
                    </div>
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        if (this.props.routing!.location.search.startsWith("?token=")) {
            const split = this.props.routing!.location.search.split('=');
            if (split.length === 2) {
                const token = split[1];
                localStorage.setItem("token", token);
                push("/");
            }
        }
    }
}


// HIDDEN FIELD

// {
//     title: 'EUR/USD rate',
//     dataIndex: 'EURUSDRate',
//     key: 'EURUSDRate',
// },
// {
//     title: 'EUR/RMB rate',
//     dataIndex: 'EURRMBRate',
//     key: 'EURRMBRate',
// },
// {
//     title: 'Version',
//     dataIndex: 'version',
//     key: 'version',
// },
// {
//     title: 'EMS fee',
//     dataIndex: 'EMSfee',
//     key: 'EMSfee',
// },
