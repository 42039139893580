import React from "react";
import { Avatar, Typography, Rate } from "antd";
import { observer, inject } from "mobx-react";
import { Header } from "./Header";
import { Store } from "./Store";
import { observable, IReactionDisposer, autorun } from "mobx";
import { RouterStore } from "mobx-react-router";
import { IComponent } from "./IComponent";
import Iframe from 'react-iframe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NumberFormat from 'react-number-format';
import { faYenSign, faDollarSign } from '@fortawesome/free-solid-svg-icons'

const { Text } = Typography;

interface IObsComponent {
    component?: IComponent;
}

@inject('routing')
@observer export class ComponentPage extends React.Component<{ routing: RouterStore }, {}> {
    private dispose?: IReactionDisposer;
    private obs: IObsComponent = observable({ component: undefined });

    public render() {
        if (this.obs.component === undefined) {
            return <Header />;
        }

        return (
            <div>
                <Header />
                <div style={{ padding: 10, display: "flex", justifyContent: "space-between" }} >
                    <div>
                        <Avatar shape="square" size={64} src={this.obs.component.partImage} />
                        <br /><br />
                        <Text><b>Uploaded MPN: </b></Text>
                        <Text>{this.obs.component.uploadedMpn}</Text>
                        <br /><br />
                        <Text><b>Silicon Expert Rating: </b></Text>
                        <Rate disabled defaultValue={this.obs.component.siliconexpertGrade} />
                        <br /><br />
                        <Text><b>Crosses: </b></Text>
                        <Text>{this.obs.component.crosses}</Text>
                        <br /><br />
                        <Text><b>Datasheet: </b></Text>
                        <a href={this.obs.component.datasheetUrl} target="_blank" rel="noopener noreferrer">link</a>
                        <br /><br />
                        <Text><b>Description: </b></Text>
                        <Text>{this.obs.component.description}</Text>
                        <br /><br />
                        <Text><b>EIA 724 Code: </b></Text>
                        <Text>{this.obs.component.eia724Code}</Text>
                        <br /><br />
                        <Text><b>ERP Price: </b></Text>
                        <NumberFormat value={this.obs.component.erpPrice} displayType={'text'} decimalScale={4} fixedDecimalScale={true} thousandSeparator={true} renderText={value => <div><FontAwesomeIcon icon={faYenSign} />{value}</div>} />
                        <Text><b>SE Price: </b></Text>
                        <NumberFormat value={this.obs.component.averagePriceSe} displayType={'text'} decimalScale={4} fixedDecimalScale={true} thousandSeparator={true} renderText={value => <div><FontAwesomeIcon icon={faDollarSign} />{value}</div>} />
                        <br /><br />
                        <Text><b>Estimated Years EOL: </b></Text>
                        <Text>{this.obs.component.estimatedYearsEol}</Text>
                    </div>
                    <Iframe width="1400px" height="900px" url={this.obs.component.datasheetUrl} />
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        this.dispose = autorun(async () => {
            const path = this.props.routing.location!.pathname;
            const split = path.split('/');
            if (split.length === 5) {
                const idf = split[2];
                const idc = split[split.length - 1];
                const func = Store.store.functions.get(idf);
                if (func !== undefined) {
                    this.obs.component = func.listComponents.filter(c => c.uploadedMpn === idc)[0];
                }
            }
            if (split.length === 9) {
                // path="/quotations/:idc/versions/:v/functions/:idf/components/:idc"
                let f = await Store.getVersionedFunctionAsync(split[2], split[4], split[6]);
                if (f === undefined) {
                    // new Quotation (not saved yet)
                    f = Store.store.functions.get(split[6])!;
                }
                this.obs.component = f!.listComponents.filter(c => c.uploadedMpn === split[8])[0];
            }
        });
    }

    public componentWillUnmount() {
        this.dispose!();
    }
}
