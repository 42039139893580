
import { observable, runInAction } from 'mobx';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import IPrice from './IPrice';
import IQuotation from './IQuotation';
import IFunction from './IFunction';
import ISettingsRate from './ISettingsRate';
import ISettings from './ISettings';

interface IStore {
    functions: ReadonlyMap<string, Readonly<IFunction>>;
    quotations: ReadonlyMap<string, Readonly<IQuotation>>;
    prices: ReadonlyMap<string, Readonly<IPrice>>;
    width: number;
    height: number;
    currentUser: string;
    adminApiKey: string;
    isAdmin: boolean;
    settings: ISettings;
}

export class Store {

    public static store: IStore = observable({
        functions: new Map(),
        quotations: new Map(),
        prices: new Map(),
        width: 0,
        height: 0,
        currentUser: "",
        adminApiKey: "",
        isAdmin: false,
        settings: { rate: { eurToRmb: 0, usdToRmb: 0 } },
    })

    public static async login() {

        console.log(process.env.REACT_APP_DEV_SIMPLE_LOGIN)
        if (process.env.REACT_APP_DEV_SIMPLE_LOGIN) // simplify login dor dev purpose
        {
            if (firebase.apps.length === 0) {
                firebase.initializeApp(JSON.parse(process.env.REACT_APP_HP_FIREBASE_APP_CONFIG!));
            }

            try {
                await firebase.auth()
                    .signInWithEmailAndPassword(process.env.REACT_APP_DEV_USER_GOOGLE!, process.env.REACT_APP_DEV_USER_GOOGLE_PWD!)
                runInAction(() => { Store.store.currentUser = process.env.REACT_APP_DEV_USER_GOOGLE! });
            } catch (error) {
                if (error.code === "auth/user-not-found") {
                    await firebase.auth()
                        .createUserWithEmailAndPassword(process.env.REACT_APP_DEV_USER_GOOGLE!, process.env.REACT_APP_DEV_USER_GOOGLE_PWD!);
                    runInAction(() => { Store.store.currentUser = process.env.REACT_APP_DEV_USER_GOOGLE! });
                }
            }
            runInAction(() => {
                Store.store.isAdmin = true;
                Store.store.adminApiKey = process.env.REACT_APP_DEV_ADMIN_API_KEY!;
            });

        } else {
            const token = localStorage.getItem("token");
            if (token === null) {
                console.log("no local storage token")
                window.location.href = process.env.REACT_APP_URL_GATE_FE!;
                return;
            }

            var response: Response = new Response();
            try {
                response = await fetch(process.env.REACT_APP_URL_API_AUTH + token);
            } catch (e) {
                console.error(e);
            }
            if (response.status === 403) {
                console.log("not authorized");
                window.location.href = process.env.REACT_APP_URL_GATE_FE!;
                return;
            }
            const cred = (await response.json());
            if (cred.groups !== undefined) {
                runInAction(() => {
                    Store.store.isAdmin = cred.groups.includes('admin');
                });
            }

            if (cred.apiKey !== undefined) {
                runInAction(() => {
                    Store.store.adminApiKey = cred.apiKey[0].key;
                });
            }

            if (firebase.apps.length === 0) {
                firebase.initializeApp(cred.app);
            }

            try {
                await firebase.auth()
                    .signInWithEmailAndPassword(cred.user.mail, cred.user.objectGUID)
                Store.store.currentUser = cred.user.name;
            } catch (error) {
                if (error.code === "auth/user-not-found") {
                    await firebase.auth()
                        .createUserWithEmailAndPassword(cred.user.mail, cred.user.objectGUID);
                    runInAction(() => {
                        Store.store.currentUser = cred.user.name;
                    });
                } else {
                    console.log(error);
                    window.location.href = process.env.REACT_APP_URL_GATE_FE!;
                    return;
                }
            }
        }


        //firebase.performance();// add ref on it if want to use custom perf
        firebase.analytics();

        firebase.firestore().collection("functions")
            .onSnapshot(Store.onFunctionsChanged);
        firebase.firestore().collection("quotations")
            .onSnapshot(Store.onQuotationsChanged);
        firebase.firestore().collection("prices").doc("prices")
            .onSnapshot(Store.onPricesChanged)
        firebase.firestore().collection("settings").doc("rate")
            .onSnapshot(Store.onSettingsChanged);

    }

    public static async getQuotationAsync(id: string): Promise<IQuotation | undefined> {
        if (id === "") {
            return undefined;
        }
        const doc = await firebase.firestore()
            .collection("quotations")
            .doc(id)
            .get();

        if (!doc.exists) {
            return undefined;
        }

        var q = doc.data() as IQuotation;

        const functions = await firebase.firestore()
            .collection("quotations")
            .doc(id)
            .collection("versions")
            .doc(q.version.toString())
            .collection("functions")
            .get();

        q.functions = functions.docs.map(f => f.data() as IFunction);

        return q;
    }

    public static async saveFunctionAsync(func: IFunction): Promise<void> {
        return firebase.firestore()
            .collection("functions")
            .doc(func.id)
            .set({ ...func, lastChange: firebase.firestore.FieldValue.serverTimestamp() });
    }

    public static async saveSettingsRate(rate: ISettingsRate): Promise<void> {
        return (firebase.firestore()
            .collection("settings")
            .doc("rate").set(rate));
    }

    public static async saveQuotationAsync(quotation: IQuotation): Promise<void> {
        const q = firebase.firestore()
            .collection("quotations")
            .doc(quotation.id);
        var v = 1;
        var nextV: firebase.firestore.DocumentReference;
        while (true) {
            nextV = firebase.firestore()
                .collection("quotations")
                .doc(quotation.id)
                .collection("versions")
                .doc(v.toString());
            if (!(await nextV.get()).exists) {
                break;
            }
            v++;
        }

        const functions = quotation.functions;
        quotation = { ...quotation, version: v, functions: [] }

        await firebase.firestore()
            .runTransaction(async t => {
                if ((await t.get(nextV)).exists) {
                    throw new Error("must retry");
                }
                t.set(q, { ...quotation, lastChange: firebase.firestore.FieldValue.serverTimestamp() });
                t.set(nextV, { ...quotation, lastChange: firebase.firestore.FieldValue.serverTimestamp() });

            });
        functions.map(async f => await nextV
            .collection("functions")
            .doc(f.id)
            .set(f));

        functions.map(async f => await firebase.firestore()
            .collection("functions")
            .doc(f.id)
            .update({ "mostRecentUse": firebase.firestore.FieldValue.serverTimestamp() }));
    }

    public static async duplicateQuotationAsync(quotation: IQuotation): Promise<void> {
        const q = firebase.firestore()
            .collection("quotations")
            .doc();

        await q.set({ ...quotation, id: q.id, name: quotation.name + " - Copy", version: 1, functions: [], lastChange: firebase.firestore.FieldValue.serverTimestamp() });

        const v = firebase.firestore()
            .collection("quotations")
            .doc(q.id)
            .collection("versions")
            .doc("1");

        await v.set({ ...quotation, id: q.id, name: quotation.name + " - Copy", version: 1, functions: [], lastChange: firebase.firestore.FieldValue.serverTimestamp() });

        quotation.functions.map(async f => await v
            .collection("functions")
            .doc(f.id)
            .set(f));

        quotation.functions.map(async f => await firebase.firestore()
            .collection("functions")
            .doc(f.id)
            .update({ "mostRecentUse": firebase.firestore.FieldValue.serverTimestamp() }));
    }

    private static onFunctionsChanged(col: firebase.firestore.QuerySnapshot): void {
        runInAction(() => {
            Store.store.functions = new Map(col.docs.map(d => [d.id, d.data() as Readonly<IFunction>]));
        });
    }

    private static onPricesChanged(doc: firebase.firestore.DocumentSnapshot): void {
        runInAction(() => {
            if (doc.data() !== undefined) {
                Store.store.prices = new Map(Object.entries(doc.data() as Readonly<{ [key: string]: IPrice }>))
            } else {
                Store.store.prices = new Map();
            }
        });
    }

    private static onQuotationsChanged(col: firebase.firestore.QuerySnapshot): void {
        runInAction(() => {
            Store.store.quotations = new Map(col.docs.map(d => [d.id, d.data() as Readonly<IQuotation>]));
        });
    }

    private static onSettingsChanged(doc: firebase.firestore.DocumentSnapshot): void {
        runInAction(() => {
            Store.store.settings.rate = doc.data() as ISettingsRate;
        });
    }

    public static generateId(): string {
        var base62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        return [...Array(20).keys()]
            .map(_ => base62[Math.floor(Math.random() * base62.length)])
            .join("");
    }

    public static async getVersionedFunctionAsync(quotationId: string, version: string, functionId: string): Promise<IFunction | undefined> {
        const f = await firebase.firestore()
            .collection("quotations")
            .doc(quotationId)
            .collection("versions")
            .doc(version)
            .collection("functions")
            .doc(functionId)
            .get();

        return f.data() as IFunction;
    }

    public static async getSettingsRateAsync(): Promise<ISettings | undefined> {
        const set = await firebase.firestore().collection("settings").doc("rate").get();
        return set.data() as ISettings;
    }

    public static getSettingsRate(): ISettingsRate {
        return Store.store.settings.rate
    }
}
