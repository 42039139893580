import { observer } from "mobx-react";
import { observable } from "mobx";

import React from "react";
import { Divider, Col, Row, Card, InputNumber, Typography } from "antd";
import { Store } from "./Store"
import ISettingsRate from "./ISettingsRate"

const { Text } = Typography;


@observer export class AdminGeneral extends React.Component {

  private rate: ISettingsRate = observable(Store.getSettingsRate());

  public render() {
    return (
      <div>
        <Row style={{ marginTop: "10px" }} gutter={6}>
          <Col span={5}>
            <Card title="Currencies Settings">
              <Row>
                <Col span={8}>
                  <Text code strong>USD / RMB</Text>
                </Col>
                <Col span={16}>
                  <InputNumber value={this.rate.usdToRmb} min={0} max={10} step={0.01} onChange={e => {
                    if (e !== undefined) {
                      this.rate.usdToRmb = e
                      Store.saveSettingsRate(this.rate)
                    }
                  }} />
                </Col>
                <Col span={8}>
                  <Text code strong>EUR / RMB</Text>
                </Col>

                <Col style={{ marginBottom: "10px" }} span={16}>
                  <InputNumber value={this.rate.eurToRmb} min={0} max={10} step={0.01} onChange={e => {
                    if (e !== undefined) {
                      this.rate.eurToRmb = e
                      Store.saveSettingsRate(this.rate)
                    }
                  }} />
                </Col>
                <Divider />
                <Col span={24}>
                  <Text type="secondary"> Changing currency will automatically update all the component prices.</Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
