import { observer } from "mobx-react";
import React from "react";
import { saveAs } from "file-saver";
import IFunction from "./IFunction";
import { Button, Result, message, Upload, Divider, Statistic, Icon, Card, Col, Row, Typography } from "antd";
import { Store } from "./Store";


const { Title } = Typography;
const { Text } = Typography;
const { Paragraph } = Typography;
const { Dragger } = Upload;


@observer export class AdminFunction extends React.Component {

  state = {
    resultUpload: "info",
    resultMessage: ""
  }

  getNbFunctFromMaturity(maturity: string) {
    var count = 0;
    Store.store.functions.forEach(function (item: IFunction) {
      if (item.maturity === maturity)
        count += 1;
    })
    return (count)
  }

  processResultUpload(info: any) {
    const { status } = info.file;
    if (status === 'done') {
      this.setState({
        resultUpload: "success",
        resultMessage: info.file.response.message
      })
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      if (info.file.error) {
        this.setState({ resultUpload: "serverError" })
      } else {
        this.setState({
          resultUpload: "error",
          resultMessage: info.file.response.error
        })
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  public render() {
    const propsUploadDragger = {
      accept: '.csv',
      name: 'function',
      action: process.env.REACT_APP_API_URL + '/functions',
      headers: {
        authorization: "Bearer " + Store.store.adminApiKey
      },
      multiple: false,
    }
    return (
      <div>
        <div style={{ background: '#ECECEC', margin: '10px', padding: '20px' }}>
          <Row justify="center" align="middle" gutter={16}>
            <Col span={3}>
              <Card >
                <Statistic
                  title="Functions Total"
                  value={Store.store.functions.size}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<Icon type="block" />}
                />
              </Card>
            </Col>
            <Col span={3}>
              <Card style={{ background: '#ECECEC' }}>
                <Title level={4} style={{ textAlign: 'center', margin: 'auto' }}>Maturity</Title>
              </Card>
            </Col>
            <Col span={3}>
              <Card>
                <Statistic
                  title="Quoted"
                  value={this.getNbFunctFromMaturity("quoted")}
                  suffix={<Text>/{Store.store.functions.size}</Text>}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col span={3}>
              <Card>
                <Statistic
                  title="POC"
                  value={this.getNbFunctFromMaturity("poc")}
                  suffix={<Text>/{Store.store.functions.size}</Text>}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col span={3}>
              <Card>
                <Statistic
                  title="MP"
                  value={this.getNbFunctFromMaturity("mp")}
                  suffix={<Text>/{Store.store.functions.size}</Text>}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col span={3}>
            </Col>
            <Col span={3}>
            </Col>
            <Col span={3}>
            </Col>
          </Row>
        </div>
        <div style={{ margin: '10px', height: '100%' }}>
          <Row gutter={16}>
            <Col span={8}>
              <Row >
                <Col span={24}>
                  <Divider>Upload Function</Divider>
                  <Dragger {...propsUploadDragger} onChange={info => this.processResultUpload(info)}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                      Drop the CSV file here.
                  </p>
                    <p className="ant-upload-hint">Click or drag file to this area to upload</p>
                  </Dragger>
                </Col>
                <Col span={24}>
                  {this.state.resultUpload === "success" && <Result
                    status="success"
                    title={this.state.resultMessage}
                    subTitle="Prices and CPN will be updated soon, it might take a few minutes"
                  />}
                  {this.state.resultUpload === "error" && <Result
                    status="error"
                    title="Submission Failed"
                    subTitle="Please check and modify the following information before resubmitting."
                  >
                    <div className="desc">
                      <Paragraph>
                        <Text
                          strong
                          style={{
                            fontSize: 16,
                          }}
                        >
                          The content you submitted has the following error:
                             </Text>
                      </Paragraph>
                      <Paragraph>
                        <Icon style={{ color: 'red' }} type="close-circle" /> {this.state.resultMessage}
                      </Paragraph>
                    </div>
                  </Result>}
                  {this.state.resultUpload === "serverError" && <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, the server has an issue. Try again or contact your administrator."
                  />}
                </Col>
                <Col span={24} style={{ paddingTop: '16px' }}>
                  <Button style={{ width: "100%" }} type="primary" icon="download" size={"large"} onClick={(e) => {
                    saveAs(process.env.REACT_APP_API_URL + '/static/file/functionTemplateSe.xlsx', "functionTemplateSe.xlsx")
                  }}> Template for SE </Button>

                </Col>
              </Row>
            </Col>
            {/* <Col style={{ height: 'auto' }} span={18}>
            //   <Divider>Tutorial</Divider>
            //   <div style={{ height: "72vh" }}>
            //     <Iframe width="100%" height="100%" url={'https://ressources.ask.altyor.solutions/functionTutorial.html'} />
            //   </div>
            */}
          </Row>
        </div>
      </div>
    )
  }
}
