import { observer } from "mobx-react";
import React from "react";
import { Button, Result, message, Upload, Divider, Icon, Col, Row, Typography } from "antd";
import { saveAs } from "file-saver"
import { Store } from "./Store";

const { Text } = Typography;
const { Paragraph } = Typography;
const { Dragger } = Upload;


@observer export class AdminComponent extends React.Component {

  state = {
    resultUpload: "info",
    resultMessage: ""
  }

  processResultUpload(info: any) {
    const { status } = info.file;
    if (status === 'done') {
      this.setState({
        resultUpload: "success",
        resultMessage: info.file.response.message
      })
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      if (info.file.error) {
        this.setState({ resultUpload: "serverError" })
      } else {
        this.setState({
          resultUpload: "error",
          resultMessage: info.file.response.error
        })
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  public render() {

    const propsUploadDragger = {
      accept: '.csv',
      name: 'ids',
      action: process.env.REACT_APP_API_URL + '/erpIds',
      headers: {
        authorization: "Bearer " + Store.store.adminApiKey
      },
      multiple: false,
    }
    return (
      <div style={{ marginRight: '10px' }}>
        <Row style={{marginRight : '20px', marginLeft : '20px' }}  gutter={16}>
          <Col span={8}>
            <Row >
              <Col span={24}>
                <Divider>Upload MPN</Divider>
                <Dragger {...propsUploadDragger} onChange={info => this.processResultUpload(info)}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">
                    Drop the CSV file here.
                </p>
                  <p className="ant-upload-hint">Click or drag file to this area to upload</p>
                </Dragger>
              </Col>
              <Col span={24}>
                {this.state.resultUpload === "success" && <Result
                  status="success"
                  title={this.state.resultMessage}
                  subTitle="Prices will be updated soon, it might take a few minutes"
                />}
                {this.state.resultUpload === "error" && <Result
                  status="error"
                  title="Submission Failed"
                  subTitle="Please check and modify the following information before resubmitting."
                >
                  <div className="desc">
                    <Paragraph>
                      <Text
                        strong
                        style={{
                          fontSize: 16,
                        }}
                      >
                        The content you submitted has the following error:
                 </Text>
                    </Paragraph>
                    <Paragraph>
                      <Icon style={{ color: 'red' }} type="close-circle" /> {this.state.resultMessage}
                    </Paragraph>
                  </div>
                </Result>}
                {this.state.resultUpload === "info" && <div></div>}
                {this.state.resultUpload === "serverError" && <Result
                  status="500"
                  title="500"
                  subTitle="Sorry, the server has an issue. Try again or contact your administrator."
                />}
              </Col>
              <Col span={24} style={{ paddingTop: '16px' }}>
                <Button style={{ width: "100%" }} type="primary" icon="download" size={"large"} onClick={(e) => {
                  saveAs(process.env.REACT_APP_API_URL + '/static/file/mpnCpnTemplate.csv', "mpnCpnTemplate.csv");
                }}> Download Template </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}
