import { observer } from "mobx-react";
import { observable, IReactionDisposer, autorun } from "mobx";
import { Store } from "./Store";
import React from "react";
import { Button, Divider, Row, Col, Icon, Statistic, Card, Form, Input, InputNumber, Select } from "antd";
import * as PriceCalculator from "./Utils/PriceCalculator"
import { Typography } from 'antd';
import IQuotation from "./IQuotation";
import { PcbaModalCalcualtor } from "./pcbaPriceCalculatorModal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYenSign, faEuroSign } from '@fortawesome/free-solid-svg-icons'

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

@observer export class QuotationForm extends React.Component<{ item: IQuotation }>{
  private dispose?: IReactionDisposer;
  private obs = observable({
    totalPrice: 0,
    totalPriceSe: 0,
    smtFee: 0,
  });

  constructor(props: any) {
    super(props)
    this.showModal = this.showModal.bind(this);
  }

  state = {
    modalVisible: false,
  }

  showModal(show: boolean) {

    this.setState({ modalVisible: show })
  }
  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const formItemLayoutHorizontalOne = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    return (
      <div>
        <Divider>Quotation Informations</Divider>
        <Form {...formItemLayout}>
          <Form.Item label="Name">
            <Input
              value={this.props.item.name}
              onChange={e => this.props.item.name = e.target.value}
            />
          </Form.Item>
          <Form.Item label="Status">
            <Select value={this.props.item.status}
              onChange={(v: string) => this.props.item.status = v}
            >
              <Option value="in-progress">In Progress</Option>
              <Option value="validated">Validated</Option>
              <Option value="aborted">Aborted</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Project Id (from erp)">
            <Input
              value={this.props.item.projectId}
              onChange={e => this.props.item.projectId = e.target.value}
            />
          </Form.Item>
          <Form.Item label="Customer">
            <Input
              value={this.props.item.customer}
              onChange={e => this.props.item.customer = e.target.value}
            />
          </Form.Item>
          <Form.Item label="Quantity of products">
            <InputNumber
              precision={0}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              value={this.props.item.quantity}
              onChange={v => this.props.item.quantity = v!}
            />
          </Form.Item>
          <Form.Item label="Note">
            <TextArea
              value={this.props.item.note}
              autosize={true}
              onChange={e => this.props.item.note = e.target.value}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item {...formItemLayoutHorizontalOne} label="SMT ratio">
                <InputNumber
                  min={0}
                  max={1}
                  precision={3}
                  step={0.01}
                  value={this.props.item.SMTRatio}
                  onChange={v => this.props.item.SMTRatio = v!}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item  {...formItemLayoutHorizontalOne} label="PCB Settings">
                <Button type="primary" onClick={() => this.showModal(true)}> Change </Button>
              </Form.Item >
            </Col>
          </Row>
          <Divider>Prices</Divider>
          <Row>
            <Col span={12}>
              <Card>

                <Statistic title="PCB Price" precision={3} value={this.props.item.pcb.pricePerPcb} suffix={<FontAwesomeIcon icon={faEuroSign} />}
                  valueStyle={this.props.item.pcb.pricePerPcb * this.props.item.quantity < 500 ? { color: '#cf1322' } : { color: '#2fcf13' }}
                  prefix={this.props.item.pcb.pricePerPcb * this.props.item.quantity < 500 ? <Icon type="exclamation-circle" /> : <Icon type="check-circle" />}
                />

              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic title="SMT Fee" precision={3} value={this.obs.smtFee} suffix={<FontAwesomeIcon icon={faYenSign} />}
                  valueStyle={this.obs.smtFee * this.props.item.quantity < 2500 ? { color: '#cf1322' } : { color: '#2fcf13' }}
                  prefix={this.obs.smtFee * this.props.item.quantity < 2500 ? <Icon type="exclamation-circle" /> : <Icon type="check-circle" />} />
              </Card>
            </Col>
          </Row>
          <PcbaModalCalcualtor visible={this.state.modalVisible} quotation={this.props.item} show={this.showModal} />
          <div>
            <Row>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Total Price"
                    value={this.obs.totalPrice + (this.props.item.pcb.pricePerPcb * Store.getSettingsRate().eurToRmb)}
                    precision={2}
                    suffix={<FontAwesomeIcon icon={faYenSign} />}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Se Price"
                    value={this.obs.totalPriceSe}
                    precision={2}
                    valueStyle={this.obs.totalPriceSe > 20 ? { color: '#cf1322' } : { color: '#2fcf13' }}
                    prefix={this.obs.totalPriceSe > 20 ? <Icon type="exclamation-circle" /> : <Icon type="check-circle" />}
                    suffix="%"
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <Text>Version {this.props.item.version} {this.props.item.lastChange === undefined ? "" : "[" + this.props.item.lastChange.toDate().toLocaleString() + "]"}</Text>
        </Form>
      </div>
    );
  }
  public componentDidMount() {

    this.dispose = autorun(() => {
      this.obs.totalPrice = PriceCalculator.calculateQuotationTotalPrice(this.props.item)
      this.obs.totalPriceSe = PriceCalculator.calculateQuotationTotalPercentagePriceSe(this.props.item)
      this.obs.smtFee = this.props.item.SMTRatio * this.props.item.functions
        .map(f => f.listComponents.map(c => c.quantity).reduce((a, b) => a + b, 0))
        .reduce((a, b) => a + b, 0)
    });

  }

  public componentWillUnmount() {
    this.dispose!();
  }
}
