import './App.css';
import { createBrowserHistory } from 'history';
import { inject, observer, Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import * as React from 'react';
import { Route, Router } from 'react-router-dom';
import { Store } from './Store';
import { QuotationPage } from './QuotationPage';
import { QuotationsPage } from './QuotationsPage';
import { FunctionsPage } from './FunctionsPage';
import { FunctionPage } from './FunctionPage';
import { ComponentPage } from './ComponentPage';
import { AdminPage } from './AdminPage';
import { action } from 'mobx';
const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

export const stores = {
  routing: routingStore,
};

export const history = syncHistoryWithStore(browserHistory, routingStore);
export const push = history.push;

@inject('routing')
@observer class App extends React.Component<{ routing: RouterStore }, {}> {
  static history: any;
  public render() {
    return (
      <Provider {...stores}>
        <Router history={history}>
          <Route exact={true} path="/" component={QuotationsPage} />

          {Store.store.isAdmin && <Route exact={true} path="/admin" component={AdminPage} />}

          <Route exact={true} path="/quotations/" component={QuotationsPage} />
          <Route exact={true} path="/quotations/:id" component={QuotationPage} />
          <Route exact={true} path="/quotations/:idq/versions/:v/functions/:idf" component={FunctionPage} />
          <Route exact={true} path="/quotations/:idq/versions/:v/functions/:idf/components/:idc" component={ComponentPage} />
          <Route exact={true} path="/functions/" component={FunctionsPage} />
          <Route exact={true} path="/functions/:id" component={FunctionPage} />
          <Route exact={true} path="/functions/:idf/version/:v" component={FunctionPage} />
          <Route exact={true} path="/functions/:idf/components/:idc" component={ComponentPage} />
          {/* <Redirect to="/" />*/}
        </Router>
      </Provider>);
  }

  public UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  public componentDidMount() {
    console.log("App did mount");
    window.addEventListener('resize', () => this.updateDimensions());
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateDimensions());
  }

  private updateDimensions() {
    action(() => {
      Store.store.width = Math.max(1920, window.innerWidth);
      Store.store.height = window.innerHeight;
    });
  }
}

export default App;
