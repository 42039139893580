import React from "react";
import { inject, observer } from "mobx-react";
import { Icon, Menu, PageHeader, Row, Col } from "antd"
import { AdminFunction } from './AdminFunction'
import { AdminComponent } from './AdminComponent'
import { AdminErp } from './AdminErp'
import { AdminGeneral } from './AdminGeneral'
import { Documentation } from './AdminDocumentation'
import { RouterStore } from 'mobx-react-router';
import { push } from "./App";
import { Store } from "./Store";

@inject('routing')
@observer export class AdminPage extends React.Component<{ routing?: RouterStore }> {

  state = {
    menuChoice: 'AdminFunction',
  }

  public render() {
    const { history } = this.props.routing!;
    if (!Store.store.isAdmin) {
      push("/");
      return <div></div>;
    }

    return (
      <div style={{ height: "98vh" }}>
        <PageHeader style={{ borderBottom: '1px solid #d9d9d9' }} onBack={() => history.goBack()} title="ADMIN Dashboard" />
        <Row type="flex">
          <Col span={3}>
            <Menu
              style={{ width: '100%' }}
              defaultSelectedKeys={['AdminFunction']}
              defaultOpenKeys={['AdminFunction']}
              mode="inline"
            >
              <Menu.Item onClick={(e) => this.setState({ menuChoice: e.key })} key="AdminGeneral"><Icon type="setting" />General</Menu.Item>
              <Menu.Item onClick={(e) => this.setState({ menuChoice: e.key })} key="AdminFunction"><Icon type="block" /> Functions</Menu.Item>
              <Menu.Item onClick={(e) => this.setState({ menuChoice: e.key })} key="AdminErpPrice"><Icon type="database" />Prices ERP</Menu.Item>
              <Menu.Item onClick={(e) => this.setState({ menuChoice: e.key })} key="AdminComponent"><Icon type="build" />MPN & CPN</Menu.Item>
              <Menu.Item onClick={(e) => this.setState({ menuChoice: e.key })} key="Documentation"><Icon type="read" />Documentation</Menu.Item>
            </Menu>
          </Col>
          <Col span={21}>
            {this.state.menuChoice === 'AdminFunction' && <AdminFunction />}
            {this.state.menuChoice === 'AdminErpPrice' && <AdminErp />}
            {this.state.menuChoice === 'AdminComponent' && <AdminComponent />}
            {this.state.menuChoice === 'AdminGeneral' && <AdminGeneral />}
            {this.state.menuChoice === 'Documentation' && <Documentation />}
          </Col>
        </Row>
      </div>

    );
  }
}
