import { observer, inject } from "mobx-react";
import { observable, action } from "mobx";
import React from "react";
import { Divider, Checkbox, Row, Col, Icon, Statistic, Card, Select, Form, Input, Typography, Tag } from "antd";
import * as PriceCalculator from "./Utils/PriceCalculator"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYenSign } from '@fortawesome/free-solid-svg-icons'
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IObsFunction } from "./FunctionPage";


const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

@inject('routing')
@observer export class FunctionForm extends React.Component<{ item: IObsFunction, readonly: boolean }> {

  private obs = observable({
    inputVisible: false,
    inputValue: "",
  });

  handleClose = action((removedTag: string) => {
    this.props.item.function!.usedByLegacy = this.props.item.function!.usedByLegacy.filter(tag => tag !== removedTag);
  });

  showInput = action(() => {
    this.obs.inputVisible = true;
  });

  handleInputChange = action((e: React.ChangeEvent<HTMLInputElement>) => {
    this.obs.inputValue = e.target.value;
  });

  handleInputConfirm = action(() => {
    if (this.obs.inputValue !== "" && this.props.item.function!.usedByLegacy.indexOf(this.obs.inputValue) === -1) {
      this.props.item.function!.usedByLegacy = [...this.props.item.function!.usedByLegacy, this.obs.inputValue];
    }

    this.obs.inputVisible = false;
    this.obs.inputValue = "";
  });

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    const formItemLayoutHorizontalOne = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    const formItemLayoutHorizontalTwo = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div>
        <Divider>Function Informations</Divider>
        <Form {...formItemLayout} style={{ paddingTop: 7 }}>
          <Row>
            <Col span={12}>
              <Form.Item {...formItemLayoutHorizontalOne} label="Status">
                <Select value={this.props.item.function!.status}
                  disabled={this.props.readonly}
                  onChange={action((v: string) => this.props.item.function!.status = v)}>
                  <Option value="enable">Enable</Option>
                  <Option value="disable">Disable</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item {...formItemLayoutHorizontalTwo} label="Category" >
                <Select
                  disabled={this.props.readonly}
                  value={this.props.item.function!.category}
                  onChange={action((v: string) => this.props.item.function!.category = v)}                    >
                  <Option value="radio">Radio</Option>
                  <Option value="sensors">Sensors</Option>
                  <Option value="hmi">HMI</Option>
                  <Option value="audio">Audio</Option>
                  <Option value="power">Power</Option>
                  <Option value="processing">Processing</Option>
                  <Option value="memory">Memory</Option>
                  <Option value="measuring">Measuring</Option>
                  <Option value="others">Others</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item {...formItemLayoutHorizontalOne} label="Name">
                <Input value={this.props.item.function!.name}
                  disabled={this.props.readonly}
                  onChange={action((e: React.ChangeEvent<HTMLInputElement>) => this.props.item.function!.name = e.target.value)}></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item {...formItemLayoutHorizontalTwo} label="Id">
                <Input value={this.props.item.function!.id}
                  disabled
                  onChange={action((e: React.ChangeEvent<HTMLInputElement>) => this.props.item.function!.id = e.target.value)}></Input>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Description">
            <Input value={this.props.item.function!.description}
              disabled={this.props.readonly}
              onChange={action((e: React.ChangeEvent<HTMLInputElement>) => this.props.item.function!.description = e.target.value)}></Input>
          </Form.Item>
          <Form.Item label="Note">
            <TextArea value={this.props.item.function!.note}
              disabled={this.props.readonly}
              onChange={action((e: React.ChangeEvent<HTMLTextAreaElement>) => this.props.item.function!.note = e.target.value)}></TextArea>
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item {...formItemLayoutHorizontalOne} label="Maturity">
                <Select value={this.props.item.function!.maturity}
                  disabled={this.props.readonly}
                  onChange={action((v: string) => this.props.item.function!.maturity = v)}>
                  <Option value="quoted">Quoted</Option>
                  <Option value="poc">POC</Option>
                  <Option value="mp">MP</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item {...formItemLayoutHorizontalTwo} label="MP Only">
                <Checkbox disabled={this.props.readonly} defaultChecked={this.props.item.function!.massProductionOnly}
                  onChange={action((e: CheckboxChangeEvent) => this.props.item.function!.massProductionOnly = e.target.checked)}></Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Ressources">
            <Input value={this.props.item.function!.urlGitlab}
              disabled={this.props.readonly}
              onChange={action((e: React.ChangeEvent<HTMLInputElement>) => this.props.item.function!.urlGitlab = e.target.value)}></Input>
          </Form.Item>
          <Form.Item label="Used by">
            <div>
              {this.props.item.function!.usedBy.map(u => {
                return (<Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => { window.open(window.location.origin + `/quotations/${u.quotationId}`, "_blank", "noreferrer") }}
                  key={u.projectId}>{u.projectId}</Tag>);
              })}
              {this.props.item.function!.usedByLegacy.map(u => {
                return (<Tag onClose={() => this.handleClose(u)}
                  closable={true} key={u}>{u}</Tag>);
              })}
              {this.obs.inputVisible && (
                <Input
                  autoFocus={true}
                  type="text"
                  size="small"
                  style={{ width: 78 }}
                  value={this.obs.inputValue}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputConfirm}
                  onPressEnter={this.handleInputConfirm}
                />
              )}
              {!this.obs.inputVisible && !this.props.readonly && (
                <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                  <Icon type="plus" /> New Legacy Project ID
                </Tag>
              )}
            </div>
          </Form.Item>
          <Form.Item label="Most recent use">
            <Text>{this.props.item.function!.mostRecentUse === undefined ? "" : this.props.item.function!.mostRecentUse.toDate().toLocaleString()}</Text>
          </Form.Item>
        </Form>
        <Divider>Prices</Divider>
        <Row gutter={16} style={{ paddingLeft: 10 }}>
          <Col span={12}>
            <Card>
              <Statistic
                title="Total Price"
                value={PriceCalculator.calculateFunctionTotalPrice(this.props.item.function!)}
                precision={2}
                suffix={<FontAwesomeIcon icon={faYenSign} />}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Se Price"
                value={PriceCalculator.calculateFunctionTotalPercentagePriceSe(this.props.item.function!)}
                precision={2}
                valueStyle={PriceCalculator.calculateFunctionTotalPercentagePriceSe(this.props.item.function!) > 20 ? { color: '#cf1322' } : { color: '#2fcf13' }}
                prefix={PriceCalculator.calculateFunctionTotalPercentagePriceSe(this.props.item.function!) > 20 ? <Icon type="exclamation-circle" /> : <Icon type="check-circle" />}
                suffix="%"
              />
            </Card>
          </Col>
        </Row>
      </div >
    );
  }
}
